html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

/* CSS */
.description {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Set the number of lines to display (in this case, 3) */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.show-full {
  display: block; /* Override the line clamp to show the full description */
}
.blog_____content>p {
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}


.expandable-quill-editor {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.2s;
}

.ql-container {
  border: none;
}

.ql-editor {
  min-height: 300px;
  padding: 10px;
  outline: none;
}



